import { NProgress } from '@tanem/react-nprogress'
import React from 'react'
import Bar from './Bar'
import Container from './Container'
import './style.scss'
import Spinner from './Spinner'

export default class LoadingBar extends React.Component {
  state = {
    isLoading: false
  }

  continousStart () {
    this.setState({ isLoading: true })
  }

  complete () {
    this.setState({ isLoading: false })
  }

  render () {
    return (
      <NProgress isAnimating={this.state.isLoading}>
        {({ isFinished, progress, animationDuration }) => progress && (
          <Container
            isFinished={isFinished}
            animationDuration={animationDuration}
          >
            <Bar progress={progress} animationDuration={animationDuration} />
            <Spinner />
          </Container>
        )}
      </NProgress>
    )
  }
}