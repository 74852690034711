import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'
import postStore from './PostStore'

export class ClubStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable club = null
  @observable clubs = []

  @action async createClub (data) {
    this.isLoading = true
    const response = await api.createClub(data)
    if (response.ok) {
      await this.getClubs()
      routerStore.replace(`/clubs/${response.data.data._id}`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getClubs () {
    this.isLoading = true
    const query = {
      with: ['host', 'waiter']
    }
    const response = await api.getClubs(query)
    if (response.ok) {
      this.clubs = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getClub (id, isStarting) {
    this.isLoading = true
    if (isStarting) {
      this.club = null
    }
    const response = await api.getClub(id)
    this.isLoading = false
    if (response.ok) {
      this.club = response.data.data
      return this.club
    } else {
      showResponseError(response)
    }
  }

  @action async setHost (id, staffId) {
    const response = await api.updateClub(id, { host_id: staffId })
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
    } else {
      showResponseError(response)
    }
  }

  @action async setWaiter (id, staffId) {
    const response = await api.updateClub(id, { waiter_id: staffId })
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
    } else {
      showResponseError(response)
    }
  }

  @action async disableClub (id) {
    const response = await api.updateClub(id, { status: 'disabled' })
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
    } else {
      showResponseError(response)
    }
  }

  @action async enableClub (id) {
    const response = await api.updateClub(id, { status: 'enabled' })
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
    } else {
      showResponseError(response)
    }
  }

  @action async deleteClub (id) {
    const response = await api.deleteClub(id)
    if (response.ok) {
      routerStore.replace('/clubs')
      this.club = null
      await this.getClubs()
    } else {
      if (response.status === 422) {
        showError('Can not delete club with posts, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async updateClub (id, data) {
    const response = await api.updateClub(id, data)
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
      routerStore.push(`/clubs/${this.club._id}`)
    } else {
      showResponseError(response)
    }
  }

  @action async uploadLogo (id, file) {
    const formData = new FormData()
    formData.append('logo', file)
    this.isUploading = true
    const response = await api.uploadClubLogo(id, formData)
    if (response.ok) {
      this.club = response.data.data
      await this.getClubs()
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }

  @action async uploadMedia (id, { file, day, date, type }) {
    const formData = new FormData()
    formData.append('media', file)
    formData.append('type', type)
    day !== undefined && formData.append('day', day)
    date !== undefined && formData.append('date', date)
    this.isUploading = true
    const response = await api.uploadClubMedia(id, formData)
    if (response.ok) {
      await postStore.getClubMedias(id)
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }
}

const clubStore = new ClubStore()
export default clubStore
