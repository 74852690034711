import React, { Component } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import { Provider } from 'mobx-react'
import stores from './stores'
import Loadable from 'react-loadable'
import { ToastContainer } from 'react-toastify'
import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
// import 'react-datepicker/dist/react-datepicker.css'
import loading from './components/Loading'
import { syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'
import Loading from './utilities/loading'
import LoadingBar from './components/LoadingBar/LoadingBar';

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, stores.router);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
})

// Pages
const Login = Loadable({
  loader: () => import('./pages/Login/Login'),
  loading
})

const Register = Loadable({
  loader: () => import('./pages/Register/Register'),
  loading
})

const Page404 = Loadable({
  loader: () => import('./pages/Page404/Page404'),
  loading
})

const Page500 = Loadable({
  loader: () => import('./pages/Page500/Page500'),
  loading
})

class App extends Component {
  render () {
    return (
      <Provider {...stores}>
        <Router history={history}>
          <Switch>
            <Route exact path='/login' name='Login Page' component={Login} />
            <Route exact path='/register' name='Register Page' component={Register} />
            <Route exact path='/500' name='Page 500' component={Page500} />
            <Route exact path='/404' name='Page 404' component={Page404} />
            <Route path='/' name='Admin' component={DefaultLayout} />
          </Switch>
          <LoadingBar
            ref={ref => (Loading.setComponent(ref))}
          />
          <ToastContainer />
        </Router>
      </Provider>
    )
  }
}

export default App
