import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import dayjs from 'dayjs'
// import routerStore from './RouterStore'
// import Loading from '../utilities/loading'

export class PostStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable medias = []
  @observable posts = []
  @observable clubPosts = []
  @observable userPosts = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    search: ''
  }

  @action async getClubMedias (clubId) {
    this.isLoading = true
    const fromDate = dayjs().startOf('week').format('YYYY-MM-DD')
    const toDate = dayjs().startOf('week').add(28, "day").format('YYYY-MM-DD')
    const query = {
      $or: [
        { type: { $in: ['teaser', 'flyer'] } },
        {
          type: { $in: ['teaser-schedule', 'flyer-schedule'] },
          date: { $gte: fromDate, $lte: toDate }
        }
      ]
    }
    const response = await api.getClubPosts(clubId, { where: query })
    if (response.ok) {
      this.medias = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getClubPosts (clubId) {
    this.isLoading = true
    const query = { where: { type: { $nin: ['teaser', 'flyer', 'teaser-schedule', 'flyer-schedule'] } }, limit: 50, sort: '-created_at' }
    const response = await api.getClubPosts(clubId, query)
    if (response.ok) {
      this.clubPosts = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getPosts (params = {}) {
    this.isLoading = true
    const query = {
      where: {
        type: {
          $nin: ['teaser', 'flyer', 'teaser-schedule', 'flyer-schedule'],
        }
      },
      with: ['user', 'club'],
      sort: '-created_at'
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    const response = await api.getPosts({ query, ...params })
    if (response.ok) {
      this.posts = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async deletePost (clubId, id) {
    const response = await api.deletePost(id)
    if (response.ok) {
      await this.getPosts(clubId)
    } else {
      showResponseError(response)
    }
  }

  @action async deletePost (id) {
    const response = await api.deletePost(id)
    if (response.ok) {
      await this.getPosts({})
    } else {
      showResponseError(response)
    }
  }

  @action async deleteUserPost (userId, id) {
    const response = await api.deletePost(id)
    if (response.ok) {
      await this.getUserPosts(userId)
    } else {
      showResponseError(response)
    }
  }

  @action async getUserPosts (userId) {
    this.isLoading = true
    const query = {
      with: ['club'],
      sort: '-created_at'
    }
    const response = await api.getUserPosts(userId, query)
    if (response.ok) {
      this.userPosts = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }
}

const clubStore = new PostStore()
export default clubStore
