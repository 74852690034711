import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError } from '../utilities/utils'

export class SummaryStore {
  @observable isLoading = false

  @observable data = null
  @observable clubSummary = null

  @action async getSummary () {
    this.isLoading = true
    const response = await api.getSummary()
    if (response.ok) {
      this.data = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getClubSummary (id) {
    this.isLoading = true
    const response = await api.getClubSummary(id)
    if (response.ok) {
      this.clubSummary = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

}

const summaryStore = new SummaryStore()
export default summaryStore
