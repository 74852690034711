import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'
import { persist } from 'mobx-persist';

export class AuthStore {
  // @desc this field is not persisted
  // @ignore
  @observable isLoading = false
  @observable isStarting = true

  @persist @observable token = null
  @observable user = null
  @persist @observable refreshToken = null
  @persist @observable skipWelcome = false

  @action async checkLogged () {
    if (this.token) {
      api.setToken(this.token)
      const user = await this.getCurrentUser()
      this.isStarting = false
      if (!user) {
        routerStore.replace('/login')
      } else {
        routerStore.location.pathname === '/' && routerStore.replace('/dashboard')
      }
    } else {
      this.isStarting = false
      routerStore.replace('/login')
    }
  }

  @action async getCurrentUser () {
    const response = await api.getCurrentUser()
    if (response.ok) {
      this.user = response.data.data
      return this.user
    } else {
      if (response.status === 401) {
        routerStore.replace('/login')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async login ({ uid, password }) {
    this.isLoading = true
    const response = await api.login({ uid, password })
    if (response.ok) {
      if (response.data.data.user && response.data.data.user.role === 'admin') {
        this.token = response.data.data.token
        this.user = response.data.data.user
        this.refreshToken = response.data.data.refreshToken
        api.setToken(response.data.data.token)
        await this.getCurrentUser()
        routerStore.replace('/dashboard')
      } else {
        showError('Permission denined!')
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action logout () {
    routerStore.replace('/login')
    this.token = null
    this.user = null
  }

  @action async socialLogin (token) {
    this.isLoading = true
    const response = await api.loginSocial('facebook', token)
    if (response.ok) {
      this.token = response.data.data.token
      this.user = response.data.data.user
      this.refreshToken = response.data.data.refreshToken
      api.setToken(response.data.data.token)
      this.registerDevice()
      if (this.user.is_host) {
        // Actions.host({ type: 'reset' })
      } else if (this.user.is_waiter) {
        // Actions.waiter({ type: 'reset' })
      } else {
        if (this.user.birth_date) {
          // Actions.root({ type: 'reset' })
        } else {
          // Actions.selectGender({ title: 'Hi ' + this.user.name, user: this.user })
        }
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async register (data, video) {
    this.isLoading = true
    const response = await api.register(data)
    if (response.ok) {
      this.token = response.data.data.token
      this.user = response.data.data.user
      this.refreshToken = response.data.data.refreshToken
      api.setToken(response.data.data.token)
      video.uri && this.uploadImageUser(video.uri)
      this.registerDevice()
      // Actions.root({ type: 'reset' })
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async updateUser (user, video) {
    this.isLoading = true
    const response = await api.updateUser(user)
    if (response.ok) {
      this.user = response.data.data
      video && await this.uploadImageUser(video.uri)
      this.isLoading = false
      return true
    } else {
      showResponseError(response)
      this.isLoading = false
      return false
    }
  }
}

const authStore = new AuthStore()
export default authStore
