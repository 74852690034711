import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'

export class ReportStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable reports = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    search: ''
  }

  @action async getReports (params = {}) {
    this.isLoading = true
    const query = {
      with: ['reportee', 'reporter'],
      sort: '-created_at'
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    
    const response = await api.getReports({ query, ...params })
    if (response.ok) {
      this.reports = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async deleteReport (id) {
    const response = await api.deleteReport(id)
    if (response.ok) {
      await this.getReports({})
    } else {
      showResponseError(response)
    }
  }
}

const reportStore = new ReportStore()
export default reportStore
