import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import routerStore from './RouterStore'
// import Loading from '../utilities/loading'
import postStore from './PostStore'

export class UserStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable user = null
  @observable users = []
  @observable staffs = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    search: '',
    is_online: ''
  }

  @action async createStaff (data) {
    this.isLoading = true
    const response = await api.createUser(data)
    if (response.ok) {
      await this.getStaffs()
      routerStore.replace(`/staffs/${response.data.data._id}/edit`)
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getStaffs () {
    this.isLoading = true
    const query = {
      where: {
        role: { $in: ['waiter', 'host', 'superHost'] },
      }
    }
    const response = await api.getUsers({ query, perPage: 999 })
    if (response.ok) {
      this.staffs = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUsers (params = {}) {
    this.isLoading = true
    const query = {
      where: {
        role: { $nin: ['admin', 'host', 'waiter'] }
      }
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    if (params.is_online === 'online') {
      query.where.is_online = true
    } else if (params.is_online === 'offline') {
      query.where.is_online = { $ne: true }
    }
    const response = await api.getUsers({ query, ...params })
    if (response.ok) {
      this.users = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }

  @action async getUser (id, isStarting) {
    this.isLoading = true
    if (isStarting) {
      this.user = null
    }
    const response = await api.getUser(id)
    this.isLoading = false
    if (response.ok) {
      this.user = response.data.data
      return this.user
    } else {
      showResponseError(response)
    }
  }

  @action async blockUser (id) {
    const response = await api.updateUser(id, { status: 'blocked' })
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      await this.getStaffs()
    } else {
      showResponseError(response)
    }
  }

  @action async unblockUser (id) {
    const response = await api.updateUser(id, { status: '' })
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      await this.getStaffs()
    } else {
      showResponseError(response)
    }
  }

  @action async deleteUser (id) {
    const response = await api.deleteUser(id)
    if (response.ok) {
      this.user = null
      await this.getStaffs()
    } else {
      if (response.status === 422) {
        showError('Can not delete user, try disable it instead')
      } else {
        showResponseError(response)
      }
    }
  }

  @action async updateUser (id, data) {
    this.isLoading = true
    const response = await api.updateUser(id, data)
    this.isLoading = false
    if (response.ok) {
      this.user = response.data.data
      await this.getUser(id)
    } else {
      showResponseError(response)
    }
  }

  @action async updateStaff (id, data) {
    const response = await api.updateUser(id, data)
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
      routerStore.push(`/staffs`)
    } else {
      showResponseError(response)
    }
  }

  @action async uploadLogo (id, file) {
    const formData = new FormData()
    formData.append('logo', file)
    this.isUploading = true
    const response = await api.uploadUserLogo(id, formData)
    if (response.ok) {
      this.user = response.data.data
      await this.getUsers()
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }

  @action async uploadMedia (id, { file, day, type }) {
    const formData = new FormData()
    formData.append('media', file)
    formData.append('type', type)
    formData.append('day', day)
    this.isUploading = true
    const response = await api.uploadUserMedia(id, formData)
    if (response.ok) {
      await postStore.getUserMedias(id)
    } else {
      showResponseError(response)
    }
    this.isUploading = false
  }
}

const userStore = new UserStore()
export default userStore
