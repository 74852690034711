import { observable, action } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
// import Loading from '../utilities/loading'

export class PointStore {
  @observable isLoading = false
  @observable points = []

  @action async addPoint (data) {
    this.isLoading = true
    const response = await api.addPoint(data)
    this.isLoading = false
    if (response.ok) {
      await this.getPoints(data.user_id)
      return true
    } else {
      showResponseError(response)
    }
  }

  @action async getPoints (userId) {
    this.isLoading = true
    const response = await api.getPoints(userId)
    if (response.ok) {
      this.points = response.data.data
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }
}

const clubStore = new PointStore()
export default clubStore
