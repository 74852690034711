import { create, persist } from 'mobx-persist'
import authStore from './AuthStore'
import router from './RouterStore';
import localForage from 'localforage'
import clubStore from './ClubStore';
import postStore from './PostStore';
import userStore from './UserStore';
import reportStore from './ReportStore';
import summaryStore from './SummaryStore'
import reservationStore from './ReservationStore'
import notificationStore from './NotificationStore';
import pointStore from './PointStore';

const stores = {
  authStore,
  router,
  clubStore,
  postStore,
  userStore,
  reportStore,
  summaryStore,
  reservationStore,
  notificationStore,
  pointStore,
}

const hydrate = create({
  storage: localForage,
  jsonify: false
})

hydrate('auth', authStore).then(() => authStore.checkLogged())

export default stores


