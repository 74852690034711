import { observable, action, toJS } from 'mobx'
// import { ignore } from 'mobx-sync'
import api from '../services/ApiDefault'
import { showResponseError, showError } from '../utilities/utils'
import Loading from '../utilities/loading'
import postStore from './PostStore'

export class ReservationStore {
  @observable isLoading = false
  @observable isUploading = false

  @observable reservations = []
  @observable params = {
    perPage: 10,
    page: 1,
    status: '',
    reservation_type: '',
    club_id: ''
  }

  @action async getBookings (params = {}) {
    this.isLoading = true
    const query = {
      where: { status: { $ne: 'cancelled' } },
      with: ['user', 'club'],
      sort: { date: 'desc', created_at: 'desc' }
    }
    params = {
      ...toJS(this.params),
      ...params,
    }
    if (params.status) {
      query.where.status = params.status
    }
    if (params.club_id) {
      query.where.club_id = params.club_id
    }
    if (params.reservation_type) {
      query.where.reservation_type = params.reservation_type
    }
    const response = await api.getReservations({ query, ...params })
    if (response.ok) {
      this.reservations = response.data.data
      this.params = {
        ...params,
        page: response.data.page,
        lastPage: response.data.lastPage,
        perPage: response.data.perPage,
        total: response.data.total,
      }
    } else {
      showResponseError(response)
    }
    this.isLoading = false
  }
}

const reservationStore = new ReservationStore()
export default reservationStore
