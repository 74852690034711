
class Loading {
  static setComponent (comp) {
    this.comp = comp
  }

  static show () {
    this.comp && this.comp.continousStart()
  }

  static hide () {
    this.comp && this.comp.complete()
  }
}

export default Loading